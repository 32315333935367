import intro from "./img/intro.png";
import buy1 from "./img/buy-1.png";
import buy2 from "./img/buy-2.png";
import buy3 from "./img/buy-3.png";
import tokenomics from "./img/tokenomics.png";
import contract from "./img/img-2.png";
import footer from "./img/footer.png";
import logo from "./img/logo.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="py-5 bg-[url('./img/bg-1.png')] bg-cover bg-no-repeat">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col lg:flex-row items-center justify-between gap-5">
          <div className="w-32">
            <img src={logo} alt="" />
          </div>
          <div className="grid grid-cols-2 lg:flex items-center gap-5">
            <a className="box-shadow  border-[2px] border-black py-3 w-[120px] inline-flex items-center justify-center text-center bg-white rounded-full transition-all hover:opacity-75">
              Home
            </a>
            <a
              href="#about"
              className="box-shadow  border-[2px] border-black py-3 w-[120px] inline-flex items-center justify-center text-center bg-white rounded-full transition-all hover:opacity-75"
            >
              About
            </a>
            <a
              href="#howtobuy"
              className="box-shadow  border-[2px] border-black py-3 w-[120px] inline-flex items-center justify-center text-center bg-white rounded-full transition-all hover:opacity-75"
            >
              How To Buy
            </a>
            <a
              href="#tokenomics"
              className="box-shadow  border-[2px] border-black py-3 w-[120px] inline-flex items-center justify-center text-center bg-white rounded-full transition-all hover:opacity-75"
            >
              Tokenomics
            </a>
          </div>
        </div>

        <div
          id="about"
          className="pt-16 lg:pt-5 container mx-auto px-2 lg:px-5 flex flex-col-reverse lg:flex-row items-center justify-between gap-10"
        >
          <div className="max-w-xl box-shadow-2 px-7 lg:px-11 py-8 bg-white rounded-3xl border-[2px] border-black">
            <h1 className="uppercase text-4xl font-bold text-primary">
              only up
            </h1>
            <p className="mt-5 text-text leading-relaxed">
              Join in the adventure with Carl, a 78-year-old balloon salesman,
              as he ties thousands of balloons to his house, soaring into the
              wilds of South America in pursuit of a lifelong dream. Mirroring
              the decentralized spirit of Solana, the blockchain platform
              enabling boundless possibilities, Carl's journey takes an
              unexpected turn when he discovers Russell, an 8-year-old stowaway.
              Together, this unlikely duo navigates a lost world filled with
              danger and surprises, weaving a tale that reflects the
              decentralized essence of Solana—a platform that, like Carl's
              balloons, empowers individuals to explore uncharted territories
              and connect in unexpected ways.
            </p>
          </div>
          <div className="max-w-xs lg:max-w-md">
            <img src={intro} alt="" />
          </div>
        </div>
      </header>

      <main>
        <section className="py-10">
          <div className="container mx-auto px-2 lg:px-5 flex items-center justify-center gap-5">
            <div className="w-full max-w-xl mx-auto text-center">
              <h2 className="uppercase text-3xl lg:text-4xl font-bold text-white">
                contract address
              </h2>
              <span className="block my-10 w-full box-shadow-2 bg-white rounded-full py-3.5 px-5  lg:px-10 border-[2px] border-black text-lg">
                0x00000000000000000000000000
              </span>
              <div className="flex justify-center gap-5 mt-12">
                <a className="box-shadow  border-[2px] border-black py-3.5 w-[198px] font-bold inline-flex items-center justify-center text-center bg-secondary rounded-full transition-all hover:opacity-75 uppercase text-white">
                  buy $Onlyup
                </a>
                <a className="box-shadow  border-[2px] border-black py-3.5 w-[198px] font-bold inline-flex items-center justify-center text-center bg-secondary rounded-full transition-all hover:opacity-75 uppercase text-white">
                  chart
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          id="howtobuy"
          className="py-20 lg:py-32 bg-[url('./img/bg-2.png')] bg-cover bg-no-repeat"
        >
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h2 className="uppercase text-4xl font-bold text-white">
              how to buy!
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-10">
              <div className="py-10 px-5 lg:px-8 rounded-3xl bg-white border-[2px] border-black box-shadow-2">
                <div className="max-w-[286px] mx-auto">
                  <img src={buy1} alt="" />
                </div>
                <div className="mt-12 max-w-[286px] mx-auto">
                  <h3 className="uppercase text-xl font-semibold">
                    NEED a wallet
                  </h3>
                  <span className="block text-center mt-3">
                    Create a Phantom or any wallets that support on Solana
                  </span>
                </div>
              </div>
              <div className="py-10 px-5 lg:px-8 rounded-3xl bg-white border-[2px] border-black box-shadow-2">
                <div className="max-w-[286px] mx-auto">
                  <img src={buy2} alt="" />
                </div>
                <div className="mt-12 max-w-[286px] mx-auto">
                  <h3 className="uppercase text-xl font-semibold">
                    buy some $sol
                  </h3>
                  <span className="block text-center mt-3">
                    Get yourselves some $SOL and send to wallet to prepare for
                    the journey
                  </span>
                </div>
              </div>
              <div className="py-10 px-5 lg:px-8 rounded-3xl bg-white border-[2px] border-black box-shadow-2">
                <div className="max-w-[286px] mx-auto">
                  <img src={buy3} alt="" />
                </div>
                <div className="mt-12 max-w-[286px] mx-auto">
                  <h3 className="uppercase text-xl font-semibold">
                    swap for $onlyup
                  </h3>
                  <span className="block text-center mt-3">
                    Swap $SOL for $ONLYUP token via Raydium or Jupiter DEX
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="tokenomics" className="pt-16 lg:pt-32 pb-12">
          <div className="container mx-auto px-2 lg:px-5 text-center">
            <h2 className="uppercase text-4xl font-bold text-white">
              tokenomics
            </h2>
            <div className="flex flex-col lg:flex-row items-center justify-between mt-10 lg:mt-0">
              <div className="w-full lg:w-96">
                <div className="box-shadow-2 bg-white border-[1px] border-black rounded-3xl py-9 px-16">
                  <h4 className="text-[#A0A0A0]">Total Supply</h4>
                  <span className="font-semibold text-xl mt-5">
                    100,000,000
                  </span>
                </div>
                <div className="mt-8 box-shadow-2 bg-white border-[1px] border-black rounded-3xl py-9 px-16">
                  <h4 className="text-[#A0A0A0]">Buy-Sell Taxes</h4>
                  <span className="font-semibold text-xl mt-5">No taxes</span>
                </div>
              </div>
              <div className="max-w-[200px] lg:max-w-[227px]">
                <img src={tokenomics} alt="" />
              </div>
              <div className="w-full lg:w-96">
                <div className="box-shadow-2 bg-white border-[1px] border-black rounded-3xl py-9 px-16">
                  <h4 className="text-[#A0A0A0]">Liquidity</h4>
                  <span className="font-semibold text-xl mt-5">100% Burnt</span>
                </div>
                <div className="mt-8 box-shadow-2 bg-white border-[1px] border-black rounded-3xl py-9 px-16">
                  <h4 className="text-[#A0A0A0]">Ownership</h4>
                  <span className="font-semibold text-xl mt-5">Renounced</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="pt-12 relative">
        <div className="hidden lg:block absolute max-w-sm -right-12 bottom-0">
          <img src={footer} alt="" />
        </div>
        <div className="relative container mx-auto px-2 lg:px-5 flex flex-col-reverse lg:flex-row gap-8 justify-between items-center border-b pb-5 lg:pb-12 border-black">
          <div className="w-40">
            <img src={logo} alt="" />
          </div>
          <span className="max-w-lg text-center">
            “That might sound boring, but I think the boring stuff is the stuff
            I remember the most.” — Russell
          </span>
          <div className="flex gap-5">
            <a
              href="https://t.me/OnlyUpSOL"
              target="_blank"
              rel="noopener noreferrer"
              className="box-shadow  border-[2px] border-black py-3.5 w-[120px] font-bold inline-flex items-center justify-center text-center bg-secondary rounded-full transition-all hover:opacity-75 text-white"
            >
              Telegram
            </a>
            <a
              href="https://twitter.com/OnlyUpSOL"
              target="_blank"
              rel="noopener noreferrer"
              className="box-shadow  border-[2px] border-black py-3.5 w-[120px] font-bold inline-flex items-center justify-center text-center bg-[#3C3C3C] rounded-full transition-all hover:opacity-75 uppercase text-white"
            >
              X
            </a>
          </div>
        </div>
        <span className="relative text-center block py-10">
          Copyright © 2023 ONLYUP
        </span>
      </footer>
    </div>
  );
}

export default App;
